/*
  Qlik Sense CSS Objects Overrides
*/


/*
  Kpis
*/

.sn-kpi-center {
  justify-content: start !important;
}

.sn-kpi-measure-title {
  font-size: 16px !important;
  font-weight: 500;
  margin-bottom: 12px;

  color: rgba(0, 0, 0, 0.6) !important;
}

.sn-kpi-value {
  font-weight: 600;
  font-size: 28px !important;
}

.sn-kpi .sn-kpi-data {
  justify-content: start !important;
}

span.kpi-wrapper > .njs-cell {
  background: transparent !important
}

@media (min-width: 1001px) and (max-width: 1200px) {
  .sn-kpi-value {
    font-size: 24px !important;
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  .sn-kpi-value {
    font-size: 20px !important;
  }
}


/*
  Charts & Tables
*/

/* Title Class */
.njs-1-0d6f139 {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}


/*
  Tables
*/

/* Nebulajs table containers */
.MuiCardContent-root > div.njs-cell > div {
  padding: 0 !important;
}

.MuiTableCell-root:not(.selected) {
  background-color: #fff !important;
  color: #141E50 !important;

  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .njs-action-toolbar-popover:not(:last-of-type) {
  display: none !important;
} */

/* Hacks to overrule default Mui styles applyed by Nebulajs */

.MuiPaper-root > div[role="application"] ~ .MuiPaper-root, .MuiTableCell-head {
  background-color: #F2F2F2 !important;
}

.njs-viz .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
  background-color: white !important;
}

tr.sn-table-row {
  height: 46px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}


/*
  Filters
*/

/* Hack to fixed positioning filter details dropdown box */
div#modal-filter-data div[role=presentation] > div:nth-child(2) {
  top: 5% !important;
  right: 5% !important;
  left: initial !important;
}